import * as React from "react";
import { withPrismicPreviewResolver } from "gatsby-plugin-prismic-previews";
import { Helmet } from "react-helmet";
import { PropTypes } from 'prop-types'

const PreviewPage = ({pageContext}) => {
  return (
    <>
      <Helmet htmlAttributes={{lang: pageContext?.language ?? 'nl'}}>
        <meta charSet="utf-8" />
        <title>Prismic Preview Page</title>
        <meta
          name="description"
          content="Prismic Preview Page"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="shortcut icon" href="/favicons/icon-32x32.png" type="image/png" />
      </Helmet>
      <div>
        <h1>Loading preview…</h1>
      </div>
    </>
  );
};

PreviewPage.propTypes = {
  pageContext: PropTypes.object,
};

export default withPrismicPreviewResolver(PreviewPage);
